import ElementModel from "./ElementModel";
import Model from "@lernetz/ts-lib/src/core/model/Model";
import { makeExcerpt } from "../search/Search";


export class TitleModel extends Model implements ElementModel {

    title:string;

    public constructor(dto) {
        super(dto);
    }

    public get viewName(): string {
        return 'Title';
    }

    public matchesSearchText(searchText: string): boolean {
        return this.title.toLowerCase().indexOf(searchText) >= 0;
    }

    generateExcerpts(search: string) {
        return makeExcerpt( search, this.title );
    }
}

