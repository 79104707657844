import { SearchInput as template } from '../../templates/components/search';
import View from '@lernetz/ts-lib/src/core/view/View';
import Model from '@lernetz/ts-lib/src/core/model/Model';

export class SearchInput extends View<SearchInputModel> {

    constructor() {
        super({
            template:template,
            data: new SearchInputModel( { isOpen:false, text:'' } ),
        })
    }

    init() {
      this.data.change.filter( c => c.name == 'isOpen' && c.newValue ).subscribe( this.onSearchOpen.bind( this ) );
    }

    onSearchOpen( name:string, newValue:any ) {
      this.node.querySelector( 'input' ).focus();
    }
}


export class SearchInputModel extends Model {
    isOpen:boolean;
    text:string;
}