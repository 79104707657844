import Model from "@lernetz/ts-lib/src/core/model/Model";
import View from "@lernetz/ts-lib/src/core/view/View";
import { SearchResultTemplate } from '../../templates/components/search';
import { TermModel } from "../glossary/Term";
import { Signal } from "@lernetz/ts-lib/src/core/signal/Signal";

export class SearchResultView extends View {

    constructor() {
        super( { template: SearchResultTemplate } );
    }

}
