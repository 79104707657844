import {Image as template} from '../../templates/components/elements';
import ElementModel from './ElementModel';
import View from '@lernetz/ts-lib/src/core/view/View';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';
import Model from '@lernetz/ts-lib/src/core/model/Model';
import { makeExcerpt } from '../search/Search';

export class Image extends View<ImageModel> {

    public constructor() {
        super({ template });
    }

    protected init() {
        const img = this.node.querySelector('img');
        img.setAttribute('src', renderer.context.imagePath(this.data.image, 'large', 'png'));
    }
}

interface AssetModel {
    file_name: string;
    preset: string;
    ext: string;
}

export class ImageModel extends Model implements ElementModel {
    
    caption:string;
    image:AssetModel;
    
    public constructor(dto) {
        super(dto);
    }
    
    public get viewName(): string {
        return 'Image';
    }
    
    public matchesSearchText(searchText: string): boolean {
        return this.caption.toLowerCase().indexOf(searchText) >= 0;
    }

    generateExcerpts(search: string) {
        return makeExcerpt( search, this.caption );
    }
}

