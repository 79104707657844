import Model from "@lernetz/ts-lib/src/core/model/Model";
import { TermModel } from "../glossary/Term";
import { Signal } from "@lernetz/ts-lib/src/core/signal/Signal";

export class Search extends Model {

    terms:TermModel[];
    input:string;
    results:TermModel[];
    isOpen:boolean;

    selected:Signal<TermModel> = new Signal();

    constructor() {
        super( { terms:[], input:'', results:[], isOpen:false } );

        // listen to input changes
        this.change.filter( e => e.name == 'input' && e.newValue.length > 2 ).subscribe( this.refilter.bind( this ) );
    }


    refilter() {
        this.results = this.terms.filter( term => term.matchesSearchText( this.input.toLowerCase() ) );
    }

    fireSelected( term:TermModel ) {
        this.selected.dispatch( term );
    }

    toggleSearch() {
        this.reset();
        this.isOpen = !this.isOpen;
    }

    reset() {
        this.input = '';
        this.results = [];
    }



}

export function makeExcerpt( term:string, text:string, limit:number = 35 ) {
					
    let indices = [];

    // remove html tags
    text = text.replace(/<\/?[^>]+(>|$)/g, "");

    // get the indices within the text (positions of the matches within the text)
    text.toLowerCase().replace( new RegExp( term.toLowerCase(), 'g' ), function( match, index ) {
        indices.push( index );
        return match;
    });
    
    // iterate over the indices
    return indices.map( i => {
        // define start and stop position
        var start = Math.max( 0, i - limit );
        var stop = Math.min( text.length, i + term.length + limit );
        // make the excerpt
        var excerpt = text.substring( start, stop );

        // wrap the term with a span
        let ti = excerpt.toLowerCase().indexOf( term.toLowerCase() );
        return excerpt.substr( 0, ti ) + '<span class="highlight">' + excerpt.substr( ti, term.length ) + '</span>' + excerpt.substr( ti + term.length );
    });
}