import Html from '@lernetz/ts-lib/src/components/Html';
import Image from '@lernetz/ts-lib/src/components/Image';
import Repeat from '@lernetz/ts-lib/src/components/Repeat';
import Show from '@lernetz/ts-lib/src/components/Show';
import Trans from '@lernetz/ts-lib/src/components/Trans';
import register from '@lernetz/ts-lib/src/core/setup/register';
import App from './components/app/App';
import { Image as ElementImage, ImageModel } from './components/elements/Image';
import { ParagraphModel } from './components/elements/Paragraph';
import { TitleModel } from './components/elements/Title';
import { TermModel } from './components/glossary/Term';
import { SearchInput } from './components/search/SearchInput';
import { SearchResultView } from './components/search/SearchResult';
import { SvgIcon } from './components/svg/SvgIcon';
import { Paragraph, Title } from './templates/components/elements';
import { Term } from './templates/components/glossary';


export const registrations = register(({ model, view }) => {
    view( 'StaticImage', Image );
    view( 'Repeat', Repeat );
    view( 'Show', Show );
    view( 'Html', Html );
    view( 'Trans', Trans );

    view( 'App', App );
    view( 'SvgIcon', SvgIcon );
    view( 'SearchInput', SearchInput );
    view( 'SearchResult', SearchResultView );

    model( 'App\\Term', TermModel ).view( { template: Term } );
    model( 'App\\Image', ImageModel ).view( ElementImage );
    model( 'App\\Paragraph', ParagraphModel ).view( { template: Paragraph } );
    model( 'App\\Title', TitleModel ).view( { template:Title } );

})

