import { setup, SetupConfig } from '@lernetz/ts-lib/src/core/setup/Setup';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';
import { scan } from '@lernetz/ts-lib/src/core/view/View';

import { registrations } from './register';


export function init( config:SetupConfig ){

	setup.init( config );

	renderer.context.setup = setup;
	renderer.context.staticImagePath = function(file_name: string) {
		return decodeURIComponent(setup.route('staticImage', { file_name }).url());
	}
	renderer.context.imagePath = function(image: { file_name: string }, preset: string = 'medium', ext: string = 'png') {
		if( !image ) return 'https://via.placeholder.com/233x150/F0F0F0/000000?text=Bild fehlt';
		return decodeURIComponent(setup.route('asset', { file_name: image.file_name, preset, ext }).url());
	}

	

	scan( document.body, registrations.viewRegistrations );
};

