import ElementModel from '../elements/ElementModel';
import Model from '@lernetz/ts-lib/src/core/model/Model';

export class TermModel extends Model {

    isOpen:boolean;
    elements:ElementModel[];
    title:string;
    slug:string;
    synonyms:string[];

    constructor( dto ) {
        super( { ...dto, isOpen:false } );
    }


    public matchesSearchText(searchText: string) {
        return this.title.toLowerCase().indexOf(searchText) >= 0
            || this.synonyms.some( syno => syno.toLowerCase().indexOf( searchText ) >= 0 )
            || this.elements.some(element => element.matchesSearchText(searchText));
    }

    generateExcerpts( search:string ) {
        return this.elements.reduce( ( prev, current ) => {
            return prev.concat( current.generateExcerpts( search ) );
         }, [] );
    }
}